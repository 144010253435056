<template>
    <div class="content-div" v-if="priceDataVo && priceDataVo != ''">
        <div class="text">规则设置</div>
        <div class="form-content">
            <Form :model="priceDataVo" :label-width="120" class="form" ref="form">
                <FormItem label="抽奖规则" prop="topicName">
                    <RadioGroup v-model="priceDataVo.drawType">
                        <Radio label="1">全程每人参与一次</Radio>
                        <Radio label="2">每次答完即可抽奖</Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem>
                    <span slot="label" class="validate">中奖规则:</span>
                    <RadioGroup v-model="priceDataVo.winType">
                        <Radio label="1">先到先得</Radio>
                        <Radio label="2">
                            随机中奖
                            <Input v-if="priceDataVo.winType == '2'" style="width: 100px" placeholder="中奖率" v-model="priceDataVo.winChance" />
                            <span v-if="priceDataVo.winType == '2'">%</span>
                        </Radio>
                    </RadioGroup>
                </FormItem>
            </Form>
        </div>

        <div class="text">
            奖项设置
            <Button class="right-btn" type="primary" @click="addPrice()" icon="ios-add"></Button>
        </div>
        <div class="input-div">
            <div class="answer-div" v-for="(item, index) in priceDataVo.activityItemVos" :key="index">
                <div style="display: flex; margin-bottom: 10px">
                    <Input style="width: 200px" placeholder="奖项描述" v-model="item.itemName" />
                    <Input style="width: 100px; margin: 0 20px" type="Number" v-model="item.itemNum" placeholder="数量" />
                    <LiefengUpload
                        ref="imgData"
                        accept=".jpg,.png,.gif,.jpeg"
                        :format="['jpg', 'png', 'jpeg', 'gif']"
                        :defaultList="item.itemPhotos"
                        :showView="true"
                        :target="'image'"
                        @success="
                            (target, val) => {
                                uploadSuccess(target, val, index)
                            }
                        "
                        v-if="showImage"
                    ></LiefengUpload>
                    <Button style="margin-left: 20px" v-if="priceDataVo.activityItemVos.length >= 2" type="error" @click="deletePrice(index)" icon="ios-trash"></Button>
                </div>
            </div>
        </div>

        <div class="text">领取方式说明</div>
        <div class="form-content">
            <Form :model="priceDataVo" :label-width="120" class="form" ref="form">
                <FormItem>
                    <Input style="width: 200px" type="textarea" v-model="priceDataVo.ruleDesc" maxlength="50" placeholder="限50字"></Input>
                </FormItem>
            </Form>
        </div>

        <div class="text">领取时限</div>
        <div class="form-content">
            <Form :model="priceDataVo" :label-width="120" class="form" ref="form">
                <FormItem style="display: flex">
                    <RadioGroup v-model="priceDataVo.acquireType">
                        <Radio label="1">当天有效</Radio>
                        <Radio label="2">本周内有效</Radio>
                        <!-- <Radio label="3">自定义</Radio>
                        <div v-if="priceDataVo.acquireType == 3" style="display: flex">
                            <Input style="width: 100px; margin-left: 5px" type="Number" placeholder="输入天" v-model="priceDataVo.time" />
                            天
                        </div> -->
                    </RadioGroup>
                </FormItem>
            </Form>
        </div>

        <div class="text">领取地点</div>
        <div class="form-content">
            <Form :model="priceDataVo" :label-width="120" class="form" ref="form">
                <FormItem>
                    <Input style="width: 200px" v-model="priceDataVo.acquireAddr" placeholder="输入领取地址"></Input>
                </FormItem>
            </Form>
        </div>
    </div>
</template>

<script>
import LiefengUpload from "@/components/LiefengUpload"
export default {
    components: { LiefengUpload },
    props: {
        priceData: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            priceDataVo: {
                drawType: "1", //抽奖规则
                winType: "1", //中奖规则
                winChance: "", //中奖概率
                ruleDesc: "", //规则备注信息
                acquireType: "1", //领取时限
                acquireAddr: "", //领取地点
                // time: "", //自定义天数
                activityItemVos: [
                    {
                        itemName: "", //选项名称
                        itemNum: "", //选项库存
                        itemPhotos: "", //选项图片
                    },
                ],
            },
            showImage:true
        }
    },
    methods: {
        sumbit() {
            if (!this.priceDataVo.activityItemVos || !this.priceDataVo.activityItemVos.length) {
                this.$Message.warning({
                    content: "奖项规则至少需要一条",
                    background: true,
                })
                return
            }
            try {
                this.priceDataVo.activityItemVos.forEach((item, index) => {
                    if (!item.itemName || item.itemName == "") {
                        this.$Message.warning({
                            content: `奖项设置第${index + 1}条奖项描述不能为空`,
                            background: true,
                        })
                        throw new Error("条件不符合")
                    }
                    // if (item.itemNum == "") {
                    //     console.log('this.priceDataVo',this.priceDataVo);
                    //     this.$Message.warning({
                    //         content: `奖项设置第${index + 1}条奖项数量不能为空`,
                    //         background: true,
                    //     })
                    //     throw new Error("条件不符合")
                    // } 
                    else {
                        if (index == this.priceDataVo.activityItemVos.length - 1) {
                            this.$emit("success", {
                                ...this.priceDataVo,
                                activityItemVos: this.priceDataVo.activityItemVos.map(item => {
                                    return {
                                        itemName: item.itemName,
                                        itemNum: item.itemNum && item.itemNum != '' ? item.itemNum : 0,
                                        itemPhoto: item.itemPhotos && item.itemPhotos.length ? item.itemPhotos[0].url : "",
                                    }
                                }),
                            })
                        }
                    }
                })
            } catch (e) {
                console.log("抛出错误", e)
            }
        },
        deletePrice(index) {
            this.priceDataVo.activityItemVos.splice(index, 1)
            this.showImage = false
            this.$nextTick(()=>{
                this.showImage = true
            })
        },
        uploadSuccess(val, file, index) {
            if (file && file.url) this.priceDataVo.activityItemVos[index].itemPhotos = [{ name: "", url: file.url }]
            else this.priceDataVo.activityItemVos[index].itemPhotos = []
        },
        addPrice() {
            this.priceDataVo.activityItemVos.push({
                itemName: "", //选项名称
                itemNum: "", //选项库存
                itemPhotos: "", //选项图片
            })
        },
    },
    watch: {
        priceData: {
            deep: true,
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal == null) return
                this.priceDataVo = {
                    ...newVal,
                    activityItemVos: newVal.activityItemVos.map(item => {
                        return {
                            ...item,
                            itemPhotos: item.itemPhoto && item.itemPhoto != '' ? [{ name: "", url: item.itemPhoto }] : null,
                        }
                    }),
                }
            },
        },
    },
}
</script>

<style lang="less" scoped>
.content-div {
    .btn-div {
        margin: 0px 0 0 20px;
    }
    .item {
        border-bottom: 1px solid #ccc;
        margin: 20px 0;
        &:nth-last-child(1) {
            border-bottom: none;
        }
    }
    .item-div {
        display: flex;
        padding: 20px;
        .left {
            width: 30%;
            border-right: 1px solid #ccc;
            padding-right: 20px;
        }
        .right {
            flex: 1;
            padding-left: 20px;
            .form-content {
                margin-top: 20px;
                border-bottom: 1px solid rgba(#ccc, 0.5);
                display: flex;
                &:nth-last-child(1) {
                    border-bottom: none;
                }
                .right-form {
                    flex: 1;
                }
                .right-btn {
                    margin-left: auto;
                }
            }
        }
    }
    .text {
        font-size: 16px;
        font-weight: bold;
        line-height: 40px;
        color: #287aad;
        display: flex;
        .right-btn {
            margin-left: auto;
        }
    }
    .input-div {
        // display: flex;
    }
    .answer-div {
        margin-left: 20px;
    }
    .validate {
        &::before {
            content: "*";
            display: inline-block;
            margin-right: 4px;
            line-height: 1;
            font-family: SimSun;
            font-size: 14px;
            color: #ed4014;
        }
    }
}
</style>